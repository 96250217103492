import React from "react";
import './App.css';
import RouteMap from "./router/router";

function App() {
  return (
      <div className="App">
        <RouteMap />
      </div>
  );
}

export default App;