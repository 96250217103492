import React, {lazy,Suspense} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
const Home = lazy(()=>import('../pages/home/home'))
const Login = lazy(()=>import('../pages/login/login'))
const Empty = lazy(()=>import('../pages/main/empty'))
const MainInPage = lazy(()=>import('../pages/main/mainInPage'))

class RouteMap extends React.Component {

    constructor() {
        super();
    }

    render() {
        return (
            <Router>
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path="/" element={<MainInPage></MainInPage>} />
                        <Route path="/login" element={<Login history={this.props.history} />} />
                        <Route path="/home" element={<Home currentPath={"path-query-data-earnings"}/>} />
                        <Route path="/aloneSdk" element={<Home currentPath={"path-sdk-download"}/>} />
                        <Route path="/404" element={<Empty />} />
                    </Routes>
                </Suspense>
            </Router>
        )
    }
}

export default RouteMap;