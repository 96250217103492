import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ConfigProvider} from "antd";
import moment from 'moment';
import 'dayjs/locale/zh-cn';
import locale from 'antd/locale/zh_CN';
moment.locale("zh_CN")

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
    <React.StrictMode>
        <ConfigProvider locale={locale}>
            <App />
        </ConfigProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
